.root {
  width: 100%;
  flex-direction: column;
  display: flex;
  align-items: center;
  color: white;
  min-height: 100vh;
  justify-self: center;
  --radius: 13px;
  margin-bottom: 300px;
}

.MidContainer {
  display: flex;
  /* background-color: red; */
  align-items: center;
  /* max-width: 1080px; */
  width: 90%;
}

.AccountContentContainer {
  min-height: var(--min-height);
  width: 75%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  /* background-color: rebeccapurple; */
  padding: 5px;
}

.UpdateOrderDetails {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  /* background-color: rebeccapurple; */
  padding: 10px;
  font-weight: bold;
}

.UpdateOrderDetails input {
  color: white;
  border: none;
  width: 80%;
  padding: 10px;
}

.UpdateOrderDetailsList {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 10px;
  background-color: #ffffff18;
  padding: 10px;
  flex-wrap: wrap;
  border-radius: 13px;
  justify-content: center;
}

.UpdateOrderStatusButton {
  width: 170px;
  height: 35px;
  display: flex;
  background-color: #ffffff26;
  border-radius: 13px;
  align-items: center;
  justify-content: center;
  transition: 0.5s;
  text-transform: uppercase;
  cursor: pointer;
}

.UpdateOrderStatusButtonSelected {
  background-color: #ffffffff;
  color: black;
}

.UpdateOrderStatusButton:hover {
  background-color: #ffffffff;
  color: black;
}

.ProductListContainer {
  margin-top: 40px;
  width: 100%;
  height: 1000px;
  border-radius: 20px;
  display: flex;
  gap: 15px;
  flex-wrap: wrap;
}

.AdminPreviewProductContainer {
  width: 300px;
  height: 400px;
  background-color: rebeccapurple;
  display: flex;
  border-radius: 13px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  align-items: center;
  justify-content: center;
}

.AdminPreviewProductContainer b {
  padding: 10px;
  background-color: black;
  border-radius: 5px;
}

.UploadMainContainer {
  width: 100%;
  /* background-color: rebeccapurple; */
  border-radius: 13px;
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}

.UploadMainSection {
  width: 48%;
  /* height: 400px; */
  /* background-color: rgb(66, 52, 80); */
  border-radius: 13px;
  display: flex;
  gap: 20px;
  flex-direction: column;
}

.UploadMainSectionTop {
  width: 100%;
  height: 600px;

  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 13px;
  display: flex;
  padding: 30px;
}

.UploadMainSectionTop img {
  width: 100%;
  /* height: 100%; */
  object-fit: cover;
  border-radius: 13px;
}
.UploadMainSectionTop video {
  width: 100%;
  /* height: 100%; */
  object-fit: cover;
  border-radius: 13px;
}

.UploadMainSectionBottom {
  width: 100%;
  background-color: rgba(255, 255, 255, 0.081);
  border-radius: 13px;
  display: flex;
}

.SelectProductVariant {
  width: 100%;
  background-color: #ffffff28;
  border-radius: 13px;
  display: flex;
  padding: 20px;
  gap: 10px;
  flex-wrap: wrap;
}

.SelectProductVariantView {
  width: 300px;
  min-height: 100px;
  background-color: #ffffff24;
  border-radius: 13px;
  padding: 10px;
  gap: 10px;
  display: flex;
  flex-direction: column;
}

.SelectProductVariantView .UpdateOrderStatusButton {
  width: 100%;
}

.SelectProductVariantViewItem {
  width: 100%;
  display: flex;
  gap: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.SelectProductVariantViewItem input {
  border-radius: 13px;
  background-color: rgba(20, 17, 17, 0.339);
  width: 50%;
}

.SelectProductImageItem {
  width: 100%;
  height: 300px;
  background-color: #ffffff24;
  border-radius: 13px;
  object-fit: cover;
}
