.rootContainer {
  flex-direction: column;
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 1400px;
  /* background-color: red; */
  height: 30px;
  padding: 5px;
}

.root {
  width: 100%;
  flex-direction: column;
  display: flex;
  align-items: center;
  color: white;
  /* background-color: #060606; */
  background-color: #191919;
  min-height: 100vh;
}

.logo {
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url("../../assets/logotext.png");
  height: 100%;
  width: 200px;
  align-self: flex-start;
}

.betaLogo {
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url("../../assets/logowhite.png");
  height: 50px;
  width: 300px;
  margin-top: 0px;
  margin-bottom: -20px;
}

h2 {
  font-size: 25px;
  line-height: 25px;
  margin-bottom: 0px;
}

.subTitle {
  height: 50px;
  margin-top: 20px;
  font-weight: 900;
  font-size: 25px;
}

.subMiniTitle {
  list-style: 12px;
  font-size: 12px;
}

.appleIcon {
  height: 70px;
  width: 120px;
  background-image: url("../../assets/applebutton.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  cursor: pointer;
}

.actionContainer {
  width: 100%;
  height: 70px;
  /* background-color: red; */
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.shopButton {
  height: 40px;
  width: 120px;
  background-color: rgb(255, 255, 255);
  border-radius: 5px;
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  border: 1px solid #000000;
  cursor: pointer;
}

.infoLayer {
  display: flex;
  height: 500px;
  margin-top: 90px;
  width: 100%;
  /* background-color: tomato; */
  max-width: 1400px;
  overflow: hidden;
  /* flex-wrap: wrap; */
  /* flex-direction: column; */
  /* padding: 20px; */
}

.infoLayerReverse {
  flex-direction: row-reverse;
}

.infoLayerImage {
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  height: 100%;
  width: 50%;
}

.image1 {
  background-image: url("../../assets/twophones.png");
}

.image2 {
  background-image: url("../../assets/onebentphone.png");
}

.image3 {
  background-image: url("../../assets/threephones.png");
}

.image4 {
  background-image: url("../../assets/scanImage.png");
}

.image5 {
  background-image: url("../../assets/bridge.png");
}

.infoLayerInfo {
  height: 100%;
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.infoLayerInfoTitle {
  font-weight: bolder;
  font-size: 70px;
  text-align: center;
  width: 60%;
  line-height: 90%;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
}

.infoLayerInfoSubTitle {
  font-size: 18px;
  text-align: center;
  width: 90%;
}

.infoLayerInfoMidTitle {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: bolder;
  line-height: 80%;
  font-size: 60px;
  text-align: center;
  width: 60%;
  height: 300px;
  margin-top: 30px;
  margin-bottom: 50px;
}

.infoMidTitle {
  font-weight: bolder;
  align-items: center;
  font-size: 60px;
  text-align: center;
  margin-top: 200px;
}

.infoMidSubTitle {
  display: flex;
  align-items: center;
  font-size: 20px;
  text-align: center;
}

.ShareComponent {
  margin-top: 300px;
  margin-bottom: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* height: 400px; */
}

.ShareComponentTitle {
  font-weight: bolder;
  align-items: center;
  font-size: 90px;
  text-align: center;
}

.ShareComponentImage {
  background-repeat: no-repeat;
  background-position: center;
  background-size: auto 100%;
  height: 400px;
  width: 100%;
  margin-bottom: 30px;
  margin-top: -40px;
}

.LostComponent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  font-weight: 900;
  font-size: 145px;
  margin-top: -90px;
}

.LostComponentSubTitle {
  font-size: 20px;
  text-align: center;
}

.LostComponentText {
  font-size: 13px;
  text-align: center;
  color: gray;
}

.PlaceHolderImage {
  margin-top: 50px;
  margin-bottom: 50px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  height: 50vh;
  width: 30vw;
  background-image: url("../../assets/twophones.png");
}

.revivarProductTitle {
  font-size: 25px;
}

@media only screen and (max-width: 600px) {
  .logo {
    align-self: center;
    margin-bottom: 30px;
  }

  .infoLayer {
    height: 700px;
    flex-direction: column;
  }

  .infoLayerReverse {
    flex-direction: column;
  }

  .infoLayerImage {
    height: 65%;
    width: 100%;
  }

  .infoLayerInfo {
    height: 35%;
    width: 100%;
  }

  .infoLayerInfoTitle {
    width: 100%;
    font-size: 12vw;
  }

  .infoLayerInfoMidTitle {
    font-size: 12vw;
    width: 100%;
  }
}

.ContactUsRoot {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url("../../assets/contactUsBack.png");
  height: 40vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-self: flex-start;
  font-size: 80px;
  font-weight: 700;
  margin-bottom: 100px;
}

.ContactUsMain {
  display: flex;
  gap: 40px;
  width: 600px;
}

.ContactUsMainSales {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-image: url("../../assets/contactUsBackMain.png");
  height: 300px;
  width: 100%;
  border-radius: 10px;
}

.ContactUsMain span {
  text-align: start;
}

.ContactUsMain h2 {
  background-color: white;
  color: #000000;
  height: 35px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: flex-start;
  border-radius: 5px;
  font-size: 15px;
  cursor: pointer;
  margin-bottom: 100px;
}

@media only screen and (max-width: 600px) {
  .ContactUsMain {
    flex-direction: column;
    width: 90%;
    align-items: center;
  }
}
