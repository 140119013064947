.root {
  width: 100vw;
  height: var(--nav-height);
  display: flex;
  align-items: center;
  padding-left: 5%;
  padding-right: 5%;
  --nav-height: 37px;
  background-color: rgb(0, 0, 0);
  gap: 5px;
  transition: 1s;
  z-index: 200;
}

.SleekNavBar {
  background-color: #00000000;
  position: absolute;
}

.StickerBar {
  background-color: #00000000;
  position: fixed;
}

.BlurBar {
  background-color: #00000084;
  backdrop-filter: blur(10px);
}

.NavBarSection {
  width: 100%;
  height: 100%;
  display: flex;
  background-color: #441313;
}

.LogoText {
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url("../assets/logotext.png");
  height: 40%;
  width: 120px;
  margin-right: auto;
  cursor: pointer;
}

.Logo {
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url("../assets/logowhite.png");
  height: 80%;
  width: 40px;
  cursor: pointer;
}

.DownloadApp {
  width: 100px;
  height: 25px;
  background-color: #00d4b51a;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 14px;
}

.SignInButton {
  width: 90px;
  height: 25px;
  background-color: #ffffffff;
  color: black;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
}

.DownloadApp:hover {
  background-color: #00000043;
  transition: 0.5s;
}

.CartButton {
  display: flex;
  cursor: pointer;
  height: calc(var(--nav-height));
  display: flex;
  justify-content: center;
  align-items: center;
}

.CartButtonCount {
  color: #000000;
  background-color: #ffffff;
  width: 20px;
  border-radius: 40px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-weight: bold;
  margin-left: -10px;
  margin-top: -12px;
}

.CartButtonIcon {
  background-image: url("../assets/cartIcon.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  height: 20px;
  width: 20px;
}

.ProfileButtonIcon {
  background-image: url("../assets/profileIcon.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  height: 20px;
  width: 20px;
}

video {
  object-fit: cover;
}

@media only screen and (max-width: 600px) {
  .DownloadApp {
    display: none;
  }
}
