.root {
  width: 100%;
  min-height: 200vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* background-color: red; */
  --controller-width: 100%;
  --sheet-color: #ffffff11;
  --border-radius: 17px;
}

.VideoTop {
  width: 100%;
  height: 100vh;
  margin-bottom: 20px;
}

.PosterPreview {
  width: 80%;
  min-height: 400px;
  margin-top: 40px;
  display: flex;
  flex-direction: row;
  /* align-items: center; */
  justify-content: center;
  gap: 10px;
  /* background-color: green; */
  margin-bottom: 100px;
}

@media only screen and (max-width: 600px) {
  .PosterPreview {
    flex-direction: column;
    min-height: 300px;
    width: 95%;
  }
}

.PosterPreviewAction {
  width: 50%;
  display: flex;
  align-items: center;
  /* justify-content: center; */
  gap: 10px;
  /* background-color: rgb(5, 58, 5); */
  flex-direction: column;
}

.PosterImmersedContainer {
  width: 50%;
  height: 630px;
  /* background-color: rebeccapurple; */
  display: flex;
  align-items: center;
  flex-direction: column;
}

@media only screen and (max-width: 600px) {
  .PosterPreviewAction {
    width: 100%;
  }
  .PosterImmersedContainer {
    width: 100%;
    height: 430px;
  }
}

.VideoFibre {
  background-color: rgb(98, 0, 134);
  width: 360px;
  height: 360px;
}

.VideoFibreSlide {
  background-color: rgb(98, 0, 134);
  width: 0px;
  height: 0px;
  position: absolute;
}

.ImageFibre {
  background-color: rgb(32, 24, 34);
  width: 360px;
  height: 360px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.polaroidButton {
  width: 300px;
  height: 35px;
  background-color: white;
  border: none;
  border-radius: 10px;
  /* margin-top: 10px; */
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-weight: bold;
  font-size: 15px;
}

.polaroidButtonMemo {
  background-color: rgba(255, 255, 255, 0.063);
  color: rgb(255, 254, 254);
}

.polaroidButtonDisabled {
  background-color: rgba(255, 255, 255, 0.615);
  color: black;
  cursor: not-allowed;
}

.sliderContainer {
  width: 300px;
  background-repeat: no-repeat;
  height: 40px;
  background-position: center;
  /* background-color: red; */
  display: flex;
  align-items: center;
}

.slider {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: 10px;
  background: #d3d3d3;
  outline: none;
  border-radius: 10px;
  opacity: 0.95;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
}

.slider:hover {
  opacity: 1;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 50px;
  height: 25px;
  border-radius: 10px;
  /* border: 7px solid #6c6c6c; */
  background: #ffffff;
  cursor: pointer;
}

.DoubleButtonStack {
  display: flex;
  width: var(--controller-width);
  gap: 10px;
  /* background-color: red; */
  /* min-height: 10px; */
}

.playButton {
  background-repeat: no-repeat;
  background-position: center;
  background-size: 20px;
  height: 40px;
  width: 40px;
  background-image: url("../../assets/playButtton.png");
  cursor: pointer;
  padding: 17px;
  background-color: #e4e4e42f;
  border-radius: 13px;
  width: 200px;
  margin-top: 10px;
}

.ExpandButton {
  background-repeat: no-repeat;
  background-position: center;
  background-size: 20px;
  height: 40px;
  width: 40px;
  background-image: url("../../assets/ExpandPoster.png");
  cursor: pointer;
  padding: 17px;
  background-color: #e4e4e42f;
  border-radius: 13px;
  width: 200px;
  margin-top: 10px;
}

@media only screen and (max-width: 600px) {
  .ExpandButton {
    width: 50vw;
  }
  .playButton {
    width: 100%;
  }
}

.CollapseButton {
  background-image: url("../../assets/singlePoster.png");
}

.playButton:hover {
  background-color: #ffffff34;
}

.pausePlayButton {
  background-image: url("../../assets/pauseButton.png");
}

.CompareButton {
  background-image: url("../../assets/comparePosters.png");
}

.ControlsStacker {
  background-color: var(--sheet-color);
  border-radius: 17px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
}

.ControlsStacker u {
  font-size: 12px;
  cursor: pointer;
  color: #ffffff8b;
}

.SelectCodeLocation {
  width: 100%;

  height: 80px;
  /* background-color: rebeccapurple; */
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.SelectCodeLocationButtonList {
  width: 100%;
  height: 50%;
  display: flex;
  gap: 10px;
}

.SelectCodeLocationButton {
  width: 100%;
  height: 100%;
  max-height: 30px;
  background-color: #ffffff2a;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 7px;
  cursor: pointer;
}

.SelectCodeLocationButton:hover {
  background-color: #ffffff;
  color: black;
}

.SelectCodeLocationButtonSelected {
  background-color: #ffffff;
  color: black;
}

.AddToCartSpacer {
  margin-top: auto;
  margin-bottom: 20px;
}

.PlayButtonStack {
  display: flex;
  gap: 10px;
}
