.root {
  width: 100vw;
  min-height: 100vh;
  background-color: #252525;
  display: flex;
  flex-direction: column;
  align-items: center;
  --radius: 13px;
  --width: 90%;
  --sheet-layer-color: #000000a0;
  color: white;
}

.MainContentContainer {
  --min-height: 700px;
  min-height: var(--min-height);
  width: 90%;
  margin-top: 40px;
  display: flex;
  /* background-color: rgb(58, 39, 78); */
}

.AccountControlContainer {
  min-height: var(--min-height);
  width: 25%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  /* background-color: red; */
  padding: 5px;
}

.AccountControl {
  width: 100%;
  /* height: 300px; */
  display: flex;
  flex-direction: column;
  border-radius: var(--radius);
  background-color: var(--sheet-layer-color);
  padding: 20px;
  gap: 10px;
}

.AccountControlButton {
  display: flex;
  /* justify-content: center; */
  align-items: center;
  width: 100%;
  height: 40px;
  /* background-color: aqua; */
  border-radius: var(--radius);
  padding: 10px;
  gap: 10px;
  transition: 0.5;
  cursor: pointer;
}

.AccountControlButton:hover {
  /* background-color: rgba(0, 0, 0, 0.589); */
  font-weight: bold;
}

.AccountControlButton span {
  height: 25px;
  width: 25px;
  aspect-ratio: 1/1;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  cursor: pointer;
}

.AccountProfileIcon {
  background-image: url("../assets/profileIcon.png");
}
.AccountOrdersIcon {
  background-image: url("../assets/ordersIcon.png");
}
.AccountCardIcon {
  background-image: url("../assets/paymentBookIcon.png");
}

.AccountContentContainer {
  min-height: var(--min-height);
  width: 75%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  /* background-color: rebeccapurple; */
  padding: 5px;
}

.AccountContentNameContainer {
  width: 100%;
  height: 200px;
  display: flex;
  flex-direction: row;
  padding: 10px;
  border-radius: var(--radius);
  background-color: var(--sheet-layer-color);
  gap: 10px;
}

.AccountContentProfilePicture {
  width: 20%;
  aspect-ratio: 1/1;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: royalblue; */
}

.AccountContentProfileImage {
  width: 90%;
  height: 90%;
  display: flex;
  border-radius: 15px;
  overflow: hidden;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.AccountContentProfileImageUpload {
  width: 100%;
  height: 100%;
  opacity: 1;
  transition: opacity 0.5s;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: bold;
}

.AccountContentProfileImageUpload:hover {
  background-color: rgba(5, 6, 7, 0.321);
  opacity: 1;
  background-image: url("../assets/uploadIcon.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 20%;
}

.AccountContentProfileInfo {
  width: 80%;
  aspect-ratio: 1/1;
  display: flex;
  padding: 20px;
  padding-left: 0px;
  font-size: 20px;
  flex-direction: column;
  align-items: flex-start;
  /* background-color: red; */
  height: 100%;
}
.AccountContentProfileInfo b {
  font-size: 25px;
}
.AccountContentProfileInfo span {
  margin-bottom: auto;
}
.AccountContentProfileAction {
  font-size: 14px;
  text-decoration: underline;
  cursor: pointer;
}

.AccountContentOrderCount {
  width: 30%;
  aspect-ratio: 1/1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: var(--radius);
  background-color: var(--sheet-layer-color);
  font-size: 30px;
  padding: 10px;
}

.AccountContentOrderCount span {
  font-size: 100px;
  font-weight: bolder;
}

.AccountContentOrders {
  width: 100%;
  min-height: 400px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 5px;
  border-radius: var(--radius);
  background-color: var(--sheet-layer-color);
  font-size: 20px;
  padding: 20px;
  gap: 20px;
  /* max-height: 900px; */
  /* overflow-y: scroll; */
}

.AccountContentOrderBar {
  width: 100%;
  height: 150px;
  display: flex;
  flex-direction: row;
  padding: 5px;
  border-radius: var(--radius);
  background-color: rgba(255, 255, 255, 0.172);
  padding: 10px;
  gap: 20px;
}

.AccountContentOrderBarImage {
  height: 100%;
  aspect-ratio: 1/1;
  display: flex;
  flex-direction: row;
  padding: 5px;
  border-radius: var(--radius);
  background-color: rgb(41, 26, 26);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.AccountContentOrderBarDetails {
  height: 100%;
  width: 40%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 5px;
  border-radius: var(--radius);
  /* background-color: rgb(158, 86, 86); */
  font-size: 15px;
}

.AccountContentOrderBarViewMore {
  height: 37px;
  width: 10%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5px;
  border-radius: var(--radius);
  background-color: rgb(83, 83, 83);
  font-size: 15px;
  transition: 0.5s;
  cursor: pointer;
  margin-left: auto;
}

.AccountContentOrderBarViewMore:hover {
  background-color: rgb(29, 16, 16);
}

@media only screen and (max-width: 600px) {
  .AccountContentOrderBar {
    padding: 5px;
    height: 150px;
    gap: 5px;
  }

  .AccountContentOrderBarDetails {
    font-size: 12px;
    text-align: start;
    white-space: nowrap;
    /* overflow: hidden; */
    text-overflow: ellipsis;
    /* background-color: rebeccapurple; */
  }

  .AccountContentOrderBarViewMore {
    height: 25px;
    width: auto;
    margin-top: auto;
    margin-left: -4%;
    border-radius: 7px;
  }
}

.OrdersPagination {
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: auto;
}

.OrdersPaginationButton {
  height: 25px;
  width: 90px;
  background-color: rgba(255, 255, 255, 0.209);
  border-radius: 10px;
  transition: 0.5s;
  cursor: pointer;
  font-size: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.OrdersPaginationButton:hover {
  background-color: rgb(255, 255, 255);
  color: black;
}

.OrdersPaginationSelected {
  background-color: rgb(255, 255, 255);
  color: black;
}

.AccountContentOrderContainer {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}

.GalleryContainer {
  width: 100%;
  min-height: 400px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 5px;
  border-radius: var(--radius);
  background-color: var(--sheet-layer-color);
  font-size: 20px;
  padding: 20px;
  gap: 20px;
}

.GalleryAssetContainer {
  width: 100%;
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}

.GalleryAsset {
  width: 200px;
  height: 350px;
  border-radius: var(--radius);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  cursor: pointer;
}

@media only screen and (max-width: 600px) {
  .MainContentContainer {
    flex-direction: column;
    min-height: 200px;
    height: auto;
  }

  .AccountControlContainer {
    width: 100%;
    min-height: 200px;
  }

  .AccountContentContainer {
    width: 100%;
  }

  .AccountContentProfilePicture {
    width: 30%;
  }
  .AccountContentProfileInfo {
    font-size: 15px;
  }

  .AccountContentOrderCount {
    width: 100%;
  }

  .GalleryAsset {
    width: 100%;
  }
}
