.root {
  width: 100vw;
  min-height: 100vh;
  background-color: #121212;
  display: flex;
  flex-direction: column;
  align-items: center;
  --radius: 10px;
  --width: 90%;
}

.orderCompleteRoot {
  /* width: 100vw; */
  height: 100%;
  /* background-color: rgb(218, 0, 0); */
  display: flex;
  /* align-items: center; */
  justify-content: center;
}

.orderCompleteLeft {
  width: 50vw;
  max-width: 500px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.orderCompleteTitle {
  font-weight: bold;
  font-size: 40px;
  height: 30px;
}

.orderCompleteRight {
  width: 50vw;
  max-width: 500px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.OrderCompleteSummary {
  width: 400px;
  background-color: #ffffff15;
  margin-top: 50px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.OrderCompleteSummary h3 {
  line-height: 20px;
  margin: 0px;
}

.OrderCompleteSummarySection {
  width: 100%;
  min-height: 50px;
  border-radius: 10px;
  background-color: #ffffff23;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  gap: 10px;
  margin-top: 20px;
}

.OrderCompleteLine {
  width: 90%;
  display: flex;
  text-align: right;
  overflow: hidden;
}

.OrderCompleteLine b {
  margin-right: auto;
  text-align: left;
  width: 30%;
}

.OrderCompleteLine span {
  margin-right: auto;
  text-align: right;
  width: 70%;
}

.CartContainer {
  width: var(--width);
  min-height: 400px;
  background-color: #ffffff0d;
  border-radius: var(--radius);
  margin-top: 35px;
  padding: 20px;
  font-size: 30px;
  text-align: left;
  font-weight: bold;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 20px;
}

.CartContainer b {
  margin-right: auto;
}

.CartItemBar {
  width: 100%;
  height: 150px;
  background-color: #ffffff12;
  border-radius: var(--radius);
  padding: 10px;
  gap: 10px;
  display: flex;
  /* justify-content: center; */
  align-items: center;
  transition: 0.5s;
}

.CartItemBar:hover {
  background-color: #ffffff22;
}

.CartItemBar select {
  background-color: red;
  border: none;
  width: 50%;
  height: 25%;
  border-radius: var(--radius);
  background-color: #121212;
  color: white;
  text-align: center;
}

@media only screen and (max-width: 800px) {
  .CartItemControls select {
    width: 200%;
  }
}

.CartItemImage {
  width: 150px;
  height: 100%;
  /* background-color: rgb(0, 0, 0); */
  border-radius: var(--radius);
  object-fit: cover;
  border: 1px solid white;
}

.CartItemInfo {
  width: 50%;
  height: 100%;
  /* background-color: rgb(60, 0, 0); */
  border-radius: var(--radius);

  font-size: 18px;
  padding-top: 10px;
  gap: 2px;
  display: flex;
  flex-direction: column;
}

.CartItemControls {
  display: flex;
  flex-direction: column;
  /* background-color: red; */
  border-radius: var(--radius);
  margin-left: auto;
  width: 10%;
  height: 100%;
  justify-content: space-between;
  align-items: flex-end;
}

.CartItemDeleteIcon {
  height: 20px;
  width: 20px;
  margin-left: auto;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url("../assets/DeleteIcon.png");
  cursor: pointer;
}

.CartItemControls input {
  width: 50%;
  height: 25%;
  border-radius: var(--radius);
  background-color: #121212;
  border: 1px solid white;
  color: white;
  text-align: center;
}

.CartContainerTotal {
  width: 90%;
  height: 150px;
  margin-top: 20px;
  padding-right: 20px;
  /* background-color: red; */
}

.CartContainerTotalItem {
  height: 20%;
  font-size: 20px;
  width: 200px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: auto;
}

.CartContainerControls {
  width: 90%;
  /* height: 50px; */
  margin-top: 20px;
  margin-bottom: 40px;

  padding-right: 20px;
  /* background-color: red; */
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.CartContainerButton {
  height: 35px;
  font-size: 17px;
  width: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: var(--radius);
  background-color: white;
  padding: 5px;
  color: black;
  border: 1px solid white;
  cursor: pointer;
  transition: 0.5s;
}
.CartContainerButton:hover {
  background-color: rgb(255, 255, 255);
}

.CartContainerButtonIcon {
  height: 100%;
  aspect-ratio: 1;
  animation-name: spin;
  animation-duration: 1000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url("../assets/checkoutSpinner.png");
}

@keyframes spin {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0deg);
  }
}

@media only screen and (max-width: 800px) {
  .orderCompleteRoot {
    flex-direction: column;
  }
  .orderCompleteLeft {
    width: 100vw;
  }

  .orderCompleteRight {
    width: 100vw;
  }

  .OrderCompleteSummary {
    width: 90%;
    margin-bottom: 50px;
  }

  .CartItemControls input {
    width: 100%;
  }

  .CartContainerControls {
    flex-direction: column-reverse;
    gap: 100px;
  }

  .CartContainerButton {
    width: 100%;
  }
}
