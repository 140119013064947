.root {
  width: 100%;
  flex-direction: column;
  display: flex;
  align-items: center;
  color: white;
  /* background-color: #060606; */
  background-color: #e0e0de;
  min-height: 100vh;
  padding-bottom: 100px;
}

.HeaderSection {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("../../assets/saintRoom.jpeg");
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 50px;
  position: relative;
}

.HeaderSectionButton {
  width: 150px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 35px;
  background-color: #0000003f;
  backdrop-filter: blur(1px);
  cursor: pointer;
  transition: 0.5s;
  position: absolute;
  margin-top: 90vh;
}

.HeaderSectionButton:hover {
  backdrop-filter: blur(5px);
  background-color: #0000008f;
}

.BurnaboyHeaderSection {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("../../assets/burnaBackdrop.jpg");
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  font-size: 20px;
}

.SectionVideoContainer {
  width: 100%;
  height: 100vh;
  background-color: antiquewhite;
  position: relative;
}

.SectionVideoContainer video {
  width: 100%;
  height: 100%;
}

.SectionVideoContent {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  z-index: 1;
  background-color: #000000e4;
  font-weight: 600;
}

.HeaderSectionLogo {
  width: 150px;
  height: 120px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url("../../assets/TobennaWesLogo.png");
}

.PosterSection {
  width: 100%;
  min-height: 800px;
  display: flex;
  justify-content: center;
  padding-top: 50px;
  flex-wrap: wrap;
  gap: 70px;
}

.PosterContainer {
  max-width: 400px;
  width: 90%;
  height: 400px;
  /* background-color: rebeccapurple; */
}

.PosterContainerTop {
  width: 100%;
  height: 80%;
  /* background-color: rebeccapurple; */
  display: flex;
}

.PosterContainerBottom {
  width: 70%;
  height: 10%;
  /* background-color: rgb(98, 73, 73); */
  display: flex;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  color: black;
}

.PosterContainerPreview {
  width: 69%;
  height: 100%;
  /* background-color: rgb(117, 60, 60); */
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  gap: 10px;
}

.PosterContainerInfo {
  width: 30%;
  /* height: 100%; */
  /* background-color: rgb(63, 0, 0); */
  display: flex;
  justify-content: space-between;
  padding-top: 10px;
  padding-bottom: 10px;

  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  padding-top: 20px;
  padding-bottom: 30px;
}

.PosterContainerInfoSize {
  font-size: 15px;
  color: black;
}

.PosterContainerInfoSize span {
  font-size: 14px;
}

.PosterContainerAddToCart {
  width: 100%;
  height: 30px;
  background-color: white;
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  cursor: pointer;
  border: 1px solid black;
  transition: 0.5s;
}

.PosterContainerAddToCart:hover {
  border-color: white;
  color: white;
  background-color: black;
}

.PosterFrameContainer {
  width: 100%;
  height: 100%;
  /* background-color: #cecbc0; */
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.PosterFrame08 {
  --aspect-ratio: 0.8;
}
.PosterFrame075 {
  --aspect-ratio: 0.75;
}
.PosterFrame067 {
  --aspect-ratio: 0.67;
}
.PosterFrame1 {
  --aspect-ratio: 1;
}

.PosterFrame {
  --width: 90%;
  /* height: 50%; */
  width: var(--width);
  /* width: 100%;
  height: 100%; */
  /* width: calc(var(--width)); */
  /* aspect-ratio: 4 / 5; */
  /* border: 8px solid black; */
  /* background-color: #cecbc0; */
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 5px 5px 10px #00000074;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  /* background-image: url("../../assets/Frame08.png"); */
  z-index: 3;
  aspect-ratio: var(--aspect-ratio);
}

.PosterFrame08 .PosterFrame {
  background-image: url("../../assets/Frame08.png");
}
.PosterFrame075 .PosterFrame {
  background-image: url("../../assets/Frame075.png");
}
.PosterFrame067 .PosterFrame {
  background-image: url("../../assets/Frame067.png");
}

.PosterFrame1 .PosterFrame {
  background-image: url("../../assets/Frame1.png");
}

.PosterFrameContainer img {
  position: absolute;
  /* height: 90%; */
  width: 65%;
  object-fit: cover;
  display: flex;
  z-index: 1;
  /* width: var(--width); */
  aspect-ratio: var(--aspect-ratio);
}

.PosterFrameContainer video {
  position: absolute;
  width: 65%;
  object-fit: cover;
  display: none;
  /* display: flex; */
  padding: 30px;
  z-index: 1;
  aspect-ratio: var(--aspect-ratio);
}

.PosterFrameContainer span {
  position: absolute;
  z-index: 0;
  background-color: black;
  width: 60%;
  height: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.PosterFrameContainer:hover img {
  display: none;
}

.PosterFrameContainer:hover video {
  display: flex;
}

.PosterFrameStay img {
  display: flex !important;
}

.PosterFrameStay video {
  display: none !important;
}

.PosterShowVide img {
  display: none !important;
}

.PosterShowVide video {
  display: flex !important;
}

.RevivarBlackIcon {
  height: 50%;
  aspect-ratio: 1/1;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url("../../assets/logoBlack.png");
  margin-left: 10px;
  margin-right: 10px;
}

.PlayPosterContainer {
  height: 30px;
  width: 90px;
  background-color: var(--top-sheet-color);
  border-radius: var(--border-radius);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  background-repeat: no-repeat;
  background-position: center;
  background-size: auto 20px;
  background-image: url("../../assets/playButtonBlack.png");
  cursor: pointer;
  /* transition: 0.5s; */
}

.PausePosterContainer {
  background-image: url("../../assets/pauseButtonBlack.png");
}

.SelectPosterTypeContainer {
  width: 400px;
  /* width: 93%; */
  /* height: 40px; */
  background-color: rgba(0, 0, 0, 0.344);
  background-color: #262816bd;
  margin-top: 10px;
  border-radius: 13px;
  display: flex;
  padding: 5px;
  gap: 5px;
  text-transform: capitalize;
}

.SelectPosterTypeContainerBurnaBoy {
  background-color: #6a000bbd;
}

.SelectPosterType {
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 13px;
  transition: 0.5s;
  padding: 2px;
  cursor: pointer;
}
.SelectPosterType:hover {
  background-color: #221409;
}

.SelectPosterTypeSelected {
  background-color: #221409;
}

@media only screen and (max-width: 600px) {
  .SelectPosterTypeContainer {
    width: 90%;
  }

  .PosterSection {
    gap: 25px;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    padding-top: 30px;
  }
  .PosterContainer {
    height: auto;
  }

  .PosterContainerTop {
    height: auto;
  }
}
