.root {
  width: 100%;
  flex-direction: column;
  display: flex;
  align-items: center;
  color: black;
  /* background-color: #060606; */
  background-color: #e0e0de;
  min-height: 100vh;

  --border-radius: 15px;
  --top-sheet-color: #0000002f;
}

.BackButtonContainer {
  height: 30px;
  width: 90%;
  margin-top: 40px;
  display: flex;
  align-items: center;
}

.BackButton {
  cursor: pointer;
  padding-left: 5px;
}

.BackButtonIcon {
  cursor: pointer;
  height: 20px;
  width: 20px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url("../../assets/backArrow.png");
}

.MainContentContainer {
  --min-height: 700px;
  min-height: var(--min-height);
  width: 90%;
  margin-top: 40px;
  display: flex;
  /* background-color: rgb(58, 39, 78); */
}

.PosterContentContainer {
  min-height: var(--min-height);
  width: 40%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  /* background-color: rebeccapurple; */
}

.PosterContainer {
  height: 540px;
  width: 100%;
  display: flex;
  align-items: center;
}

.PosterContainer img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: var(--border-radius);
}

.PosterRelatedContainer {
  height: 150px;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 20px;
  background-color: var(--top-sheet-color);
  border-radius: var(--border-radius);
  gap: 20px;
}

.PosterRelatedContainer img {
  height: 100%;
  aspect-ratio: 4/5;
  object-fit: cover;
  border-radius: 9px;
  transition: 0.5s;
  border: 2px solid transparent;
}

.PosterRelatedContainer img:hover {
  height: 100%;
  aspect-ratio: 4/5;
  object-fit: cover;
  border-radius: 9px;
  border: 2px solid white;
}

.PosterInfoContainer {
  min-height: var(--min-height);
  width: 60%;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 20px;
  gap: 30px;
}

.PosterInfoName {
  width: 100%;
  font-weight: bold;
  display: flex;
  align-items: center;
  font-size: 35px;
}

.PosterInfoShare {
  cursor: pointer;
  height: 20px;
  width: 20px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url("../../assets/shareIcon.png");
  margin-left: 20px;
  margin-right: 5px;
}

.PosterInfoName span {
  font-size: 15px;
  font-weight: 300;
}

.PosterInfoDescription {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  font-size: 20px;
}

.PosterInfoAttributes {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  font-size: 20px;
  gap: 10px;
}

.PosterInfoAttributeContainer {
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: var(--top-sheet-color);
  font-size: 20px;
  border-radius: var(--border-radius);
  gap: 10px;
  padding: 10px;
}

.LiveEnabledIcon {
  margin: 10px;
  height: 110%;
  aspect-ratio: 1/1;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url("../../assets/arEnabledIcon.png");
}

.StillEnabledIcon {
  margin: 10px;
  height: 100%;
  aspect-ratio: 1/1;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url("../../assets/regularEnabledIcon.png");
}

.NFTEnabledIcon {
  margin: 10px;
  height: 100%;
  aspect-ratio: 1/1;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url("../../assets/nftIcon.png");
}

.OpenLinkIcon {
  margin: 10px;
  height: 90%;
  aspect-ratio: 1/1;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url("../../assets/openLinkIcon.png");
}

.RevivarBlackIcon {
  height: 100%;
  aspect-ratio: 1/1;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url("../../assets/logoBlack.png");
}

.TextureIcon {
  background-image: url("../../assets/textureIcon.png");
}
.NotNopeIcon {
  background-image: url("../../assets/notIcon.png");
}
.QRCodeIcon {
  background-image: url("../../assets/qrIcon.png");
}
.FilePictureIcon {
  background-image: url("../../assets/filePictureIcon.png");
}

.PosterAttributeInfo {
  width: 70%;
  height: 90%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  font-size: 20px;
  line-height: 15px;
  font-size: 14px;
  margin-right: auto;
}

.PosterAttributeInfo b {
  font-size: 16px;
}

.PosterInfoActionsContainer {
  width: 100%;
  height: 300px;
  display: flex;
  flex-direction: column;
  background-color: var(--top-sheet-color);
  font-size: 20px;
  border-radius: var(--border-radius);
  gap: 10px;
  padding: 15px;
}

.PosterInfoActionsPrice {
  width: 100%;
  height: 10%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 24px;
  justify-content: space-between;
}

.PosterInfoActionsQuantity {
  width: 15%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  gap: 10px;
  /* background-color: rebeccapurple; */
}

.PosterInfoActionsQuantity select {
  width: 80%;
  height: 100%;
  border-radius: 8px;
  background-color: var(--top-sheet-color);
  border: none;
  text-align: center;
  color: black;
}

.PosterInfoActionsSizePreview {
  margin-top: auto;
  font-size: 13px;
  text-align: start;
}

.PosterInfoActionsSizeList {
  height: 12%;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
}

.PosterInfoActionsSizeButton {
  width: 100px;
  height: 100%;
  background-color: var(--top-sheet-color);
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  transition: 0.5s;
  cursor: pointer;
}

.PosterInfoActionsSizeButton:hover {
  background-color: white;
}

.PosterInfoActionsSizeSelected {
  background-color: white;
}

.PosterInfoActionsAddToCart {
  width: 100%;
  height: 12%;
  background-color: rgba(255, 255, 255, 0.94);
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  transition: 0.5s;
  cursor: pointer;
  border: 2px solid white;
}

.PosterInfoActionsAddToCart:hover {
  background-color: rgb(255, 255, 255);
  border: 2px solid rgba(0, 0, 0, 0.403);
}

.PosterInfoActionsIncludedContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  background-color: var(--top-sheet-color);
  font-size: 20px;
  border-radius: var(--border-radius);
  gap: 10px;
  padding: 10px;
}

.PosterInfoActionsIncluded {
  width: 100%;
  height: 30px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  transition: 0.5s;
  cursor: pointer;
  text-align: start;
}

.ImageGalleryCanvas {
  height: 100%;
  width: 100%;
}

.ImageFibre {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.FrameImageFibre {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  /* background-color: red; */
}

.VideoFibre {
  background-color: rgb(0, 0, 0);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  position: relative;
}

.VideoFibre span {
  position: absolute;
  z-index: 0;
}

.VideoFibre video {
  position: absolute;
  z-index: 1;
}

.PlayPosterContainer {
  height: 30px;
  width: 90px;
  background-color: var(--top-sheet-color);
  border-radius: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* margin-left: auto; */
  /* margin-right: auto; */
  background-repeat: no-repeat;
  background-position: center;
  background-size: auto 20px;
  cursor: pointer;

  background-image: url("../../assets/playButtonBlack.png");
}

.PlayPosterContainer:hover {
  background-color: white;
}

.PausePosterContainer {
  background-image: url("../../assets/pauseButtonBlack.png");
}

.ExpandButton {
  background-image: url("../../assets/ExpandPosterBlack.png");
}

.CollapseButton {
  background-image: url("../../assets/singlePosterBlack.png");
}

.CompareButton {
  background-image: url("../../assets/comparePostersBlack.png");
}

.PlayButtonStack {
  display: flex;
  gap: 10px;
  justify-content: center;
}

@media only screen and (max-width: 800px) {
  .MainContentContainer {
    --min-height: 600px;
    flex-direction: column;
  }
  .PosterContentContainer {
    width: 100%;
  }
  .PosterInfoContainer {
    width: 100%;
  }
  .PosterInfoActionsQuantity {
    width: 30%;
  }
  .PosterContainer {
    height: 400px;
  }
}
